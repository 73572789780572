/* eslint-disable no-undef, react/prop-types */
import React, { Component }  from 'react';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { graphql, navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import { PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import api from '../utils/api';

import gooseberry from '../assets/images/gooseberry-black.png';
import compass from '../assets/images/compass-button.png';

const top = css`
  padding-top: 40px;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    }
`;

const noTop = css`
  padding-top: 0;
  margin-top: 0;
`;

const buttonClass = css`
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  outline: none;
`;


const buttonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;


class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    message: null,
    isSubmitting: false,
  };

  componentDidMount() {
  }

  submit = (e) => {
    e.preventDefault();
    this.setState({message: null});

    const form = e.target;
    var sendValues = {
      password: form.password.value,
      reset_key: (new URLSearchParams(location.search)).get('resetkey')
    };

    if( sendValues.password > '' && sendValues.reset_key > '' ) {
      api.resetpassword(sendValues)
      .then((res) => {
        if (res.status >= 400) {
          throw new Error('The server is down, please try later');
        }
        return (res.json());
      })
      .then((returned) => {
        this.setState({message: returned.message || "Password reset"});
      });

    } else {
      this.setState({message: "Password invalid"});
    }
  };

  render() {
    return (
    <>
      <p className={top}><img src={gooseberry} alt="Sea Gooseberry" width="200"/></p>
      <h1 className={noTop}>Beacons</h1>
      <form onSubmit={this.submit}>
        <Flex wrap={['wrap', 'nowrap', 'nowrap']}>
          <label className="form-field-label-code">
            New Password:
          </label>
          <input
            className="form-field-register"
            type="password"
            name="password"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          />
        </Flex>

        <button
          className={buttonClass}
          type="submit"
          value="resetpassword"
          disabled={this.state.isSubmitting}
        >
          <img alt="reset password" src={compass} width="20px"/>
          <span>Reset Password</span>
        </button>

        {
          this.state.message &&
          <p className="error-message">{this.state.message}</p>
        }
      </form>
    </>
    )
  };
};

const imgStyle = css`
  border-radius: 5px;
`;

const ResetPassword = ({ data }) => {
  const mapData = data.map.childImageSharp;
  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
      >
      <Flex justifyContent="center" align-items="center">
        <PaperBox
          width={[1, '80%', '60%']}
          m={['3.5rem 0', '3.5rem 0', '5.5rem auto']}
          px={[3, 3, 5]}
          py={[3, 3, 3]}
          color={colors.secondary}
          textAlign="center"
        >
          <ResetPasswordComponent></ResetPasswordComponent>
        </PaperBox>
      </Flex>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

export default ResetPassword;
